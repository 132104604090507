import {Table, Modal, Button} from 'react-bootstrap';
import {useState} from 'react';



function Staff(){
    const staff = [
        {id: "1", name : "Chandresh", email : 'cdm@repricer.com', password : '1234567890'},
        {id: "2", name : "Sandy", email : 'sandy@repricer.com', password : '1234567890'},
        {id: "3", name : "Umo", email : 'umo@repricer.com', password : '1234567890'},
        {id: "4", name : "Chandresh", email : 'cdm@repricer.com', password : '1234567890'},
        {id: "5", name : "Chandresh", email : 'cdm@repricer.com', password : '1234567890'}
    ];

    const [show, setShow] = useState(false);
    function handleClose(){
        return setShow(false);
    } 
    function handleShow(){

        return setShow(true);
    } 
    return (
        <>
        
        <h1>Staff</h1>
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Staff Name</th>
                    <th>Staff Email</th>
                    <th>action</th>
                </tr>
            </thead>
            <tbody>
                { 
                    staff.map(( obj, key) => 
                        <tr key={key}>
                            <td>{obj.id}</td>
                            <td>{obj.name}</td>
                            <td>{obj.email}</td>
                            <td>
                                <Button variant="info" onClick={() => handleShow()}>Edit</Button>
                                <Button variant="danger">Delete</Button>

                            </td>
                        </tr>
                    )
                }
            </tbody>
        </Table>

        <Modal
        show={show}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Manage Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="text" value="abc"/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save
                </Button>
                </Modal.Footer>
        </Modal>
        </>
    )
}

export default Staff;