/*import './App.css'
import React from 'react'
//example with props

function App(){
	
	return(
		<div className="App">
			<h1> React JS Example</h1>
		</div>
	)
	
}*/

import './App.css'
import React from 'react'
import Users from './Users_child_to_parent.js'
import Staff from './Staff.js'
import {Nav} from 'react-bootstrap'
//example with props

function App(){
	let name = "Chandresh Mavani";
	return(
		<>
		<Nav defaultActiveKey="/home" className="flex-column">
		<Nav.Link href="/home">Home</Nav.Link>
		<Nav.Link eventKey="link-1" href="/home">Staff</Nav.Link>
		<Nav.Link eventKey="link-2" href="/home">Customer</Nav.Link>
		<Nav.Link eventKey="disabled" disabled>
			Disabled
		</Nav.Link>
		</Nav>
			{
				//<h1> Send Data Child to parent Comp (Lifting State up)</h1>
				//<Users myName={name} />
			}
			<Staff/>
		</>
	)
	
}


export default App;